"use es6";

/* *
 *
 *  (c) 2010-2021 Torstein Honsi
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
'use strict';

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SeriesRegistry = _interopRequireDefault(require("../../Core/Series/SeriesRegistry"));
var _Utilities = _interopRequireDefault(require("../../Core/Utilities"));
;
var __extends = void 0 && (void 0).__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return _extendStatics(d, b);
  };
  return function (d, b) {
    _extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var _a = _SeriesRegistry.default.seriesTypes,
  ColumnPoint = _a.column.prototype.pointClass,
  AreaRangePoint = _a.arearange.prototype.pointClass;
var extend = _Utilities.default.extend,
  isNumber = _Utilities.default.isNumber;
/* *
 *
 *  Class
 *
 * */
var ColumnRangePoint = /** @class */function (_super) {
  __extends(ColumnRangePoint, _super);
  function ColumnRangePoint() {
    var _this = _super !== null && _super.apply(this, arguments) || this;
    _this.series = void 0;
    _this.options = void 0;
    _this.barX = void 0;
    _this.pointWidth = void 0;
    _this.shapeArgs = void 0;
    _this.shapeType = void 0;
    return _this;
  }
  ColumnRangePoint.prototype.isValid = function () {
    return isNumber(this.low);
  };
  return ColumnRangePoint;
}(AreaRangePoint);
/* *
 *
 *  Prototype properties
 *
 * */
extend(ColumnRangePoint.prototype, {
  setState: ColumnPoint.prototype.setState
});
/* *
 *
 *  Default export
 *
 * */
var _default = exports.default = ColumnRangePoint;
module.exports = exports.default;